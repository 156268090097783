import React from 'react';
import PropTypes from 'prop-types';
import styles from "./Error.module.scss";
import IconError from "./iconError.svg"

const Error = (props) => {

    const Errors = () => {
        if (typeof props.errors === "object") {
            return Object.keys(props.errors).map((item, key) => {
                if (typeof props.errors[item] === 'object') {
                    return props.errors[item].map((innerItem, innerKey) => {
                        return <div key={innerKey} dangerouslySetInnerHTML={{__html: innerItem}} />;
                    });
                } else {
                    return <div key={key} dangerouslySetInnerHTML={{__html: props.errors[item]}} />;
                }
            });
        } else {
            return props.errors;
        }
    };

    return (
        <div className={styles.error}>
            <img src={IconError} alt='Error Symbol'/>
            <Errors />
        </div>
    );

};

Error.propTypes = {
    errors: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default Error;