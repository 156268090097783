import DataSource from "../../../DataSource";
import DataStore from "../../../DataStore";
import { getTodayDate } from "../../../globals";

/**
 * 
 * @param {*} avcId 
 * @param {*} locationId 
 * @returns {
            "sqSummary": {
                "priority": [
                    "nbn"
                ],
                "availablePlanCategories": [
                    "residential",
                    "totalbiz",
                    "superbiz"
                ],
                "nbn": {
                    "locationId": "LOC000040555667",
                    "serviceClass": 3,
                    "serviceClassDescription": "Serviceable by Fibre, drop and NTD in place",
                    "technology": "Fibre",
                    "technologyShortCode": "FTTP",
                    "maximumDownloadSpeed": 1000,
                    "maximumUploadSpeed": 400,
                    "newDevelopmentsChargeApplies": false,
                    "activeService": 1,
                    "readyDate": null,
                    "ntdCheck": false,
                    "copperLines": [],
                    "ntds": [
                        {
                            "ntdId": "NTD000054372347",
                            "dataPorts": [
                                {
                                    "id": "1-UNI-D2",
                                    "status": "Free",
                                    "serviceIdMatch": false
                                },
                                {
                                    "id": "1-UNI-D1",
                                    "status": "Used",
                                    "serviceIdMatch": true,
                                    "serviceProviderId": "0338",
                                    "productInstanceId": "PRI000291261707",
                                    "serviceProviderName": "Exetel Pty Ltd"
                                },
                                {
                                    "id": "1-UNI-D3",
                                    "status": "Free",
                                    "serviceIdMatch": false
                                },
                                {
                                    "id": "1-UNI-D4",
                                    "status": "Free",
                                    "serviceIdMatch": false
                                }
                            ],
                            "voicePorts": []
                        }
                    ],
                    "locationDetails": {
                        "formattedAddress": "7 HARVEY COURT, WANGARATTA VIC 3677",
                        "address": {
                            "roadNumber1": "7",
                            "roadName": "HARVEY",
                            "roadTypeCode": "CT",
                            "localityName": "WANGARATTA",
                            "stateTerritoryCode": "VIC",
                            "postcode": "3677",
                            "unitNumber": null,
                            "unitTypeCode": null,
                            "levelNumber": null,
                            "levelTypeCode": null,
                            "addressSiteName": null,
                            "roadNumber2": null,
                            "lotNumber": null,
                            "roadSuffixCode": null,
                            "secondaryComplexName": null,
                            "complexRoadNumber1": null,
                            "complexRoadNumber2": null,
                            "complexRoadName": null,
                            "complexRoadTypeCode": null,
                            "complexRoadSuffixCode": null
                        },
                        "poi": "2ALB"
                    },
                    "notes": null
                }
            }
        }
 */
export const getServiceQualificationByAVCAndLocation = async(avcId, locationId) =>{
    try {
        const provider = DataStore.getItem("provider")
        const response = await DataSource.serviceQualification(`serviceId=${avcId}&${provider}=${locationId}&customerAuthorityDate=${getTodayDate()}`)
        if (response && response.sqSummary) {
            return response.sqSummary[provider]
        }
        return response
    } catch(exception) {
        throw(exception)
    }
    
}

function findMatchingNTDService(ntds) {
        
    for (const ntd of ntds) {
        for (const port of ntd.dataPorts) {
            if (port.serviceIdMatch) {
                return port;
            }
        }
    }
    return null;  // If no match is found
}

function findMatchingCopperService(copperLines) {
    for (const copper of copperLines) {
        if (copper.serviceIdMatch) {
            return copper;
        }
    }
}

export const getNTDOrCopperDatafromSQ = (sqSummary) => {
    try {
        if (sqSummary.ntds.length > 0) {
            const ntdData = findMatchingNTDService(sqSummary.ntds)
            return ntdData
        } else if (sqSummary.copperLines.length > 0 ) {
            const copperData = findMatchingCopperService(sqSummary.copperLines)
            return copperData
        }
        return null
    } catch(exception) {
        throw(exception)
    }
}