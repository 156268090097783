const Loader = () => {
    return (
      <svg
        width="80px"
        height="20px"
        viewBox="0 0 100 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="20" cy="10" r="5" fill="#004144">
          <animate
            attributeName="r"
            values="5;8;5"
            dur="0.6s"
            repeatCount="indefinite"
            begin="0s"
          />
        </circle>
        <circle cx="50" cy="10" r="5" fill="#00a884">
          <animate
            attributeName="r"
            values="5;8;5"
            dur="0.6s"
            repeatCount="indefinite"
            begin="0.2s"
          />
        </circle>
        <circle cx="80" cy="10" r="5" fill="#a9fad1">
          <animate
            attributeName="r"
            values="5;8;5"
            dur="0.6s"
            repeatCount="indefinite"
            begin="0.4s"
          />
        </circle>
      </svg>
    );
  };
  
  export default Loader;  