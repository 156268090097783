import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import styles from "./Summary.module.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { gsap } from "gsap";
import OrderContext from "../../OrderContext";
import arrowGreen from "../../images/icon-arrow-green.svg";
import {mapRouterData, TYPE_BROADBAND, TYPE_MOBILE, NTD_LABEL} from "../../constants";
import {formatDecimals, stripTags} from "../../globals";
import { useSelector } from "react-redux";
import Banner from "../Banner/Banner";

const Summary = (props) => {

    let { order, orderType } = useContext(OrderContext);
    const [isOpen, setIsOpen] = useState(false);
    const [orderOverride, setOrderOverride] = useState(order);
    const expandableRef = useRef(null);
    let tl = gsap.timeline();
    let promoStart = new Date(2022, 10, 1); // Date is 0-indexed. Promo starts 1 nov 2022
    let promoEnd = new Date(2023, 7, 30);

    const upsell = useSelector(state => state.upsell.value);

    useEffect(() => {
        //todo: Overriding order for now - will move order object to
        // Redux in the future and orderOverride will be redundant
        setOrderOverride({...order});
        //HARDCODED REMOVAL OF EXESIM should be correct in the api in the first place
        if (orderOverride.planName) {
            orderOverride.planName = orderOverride.planName.replace("ExeSim ", "")
        }
    }, [order]);

    useEffect(() => {
        setOrderOverride({...order, ...upsell});
    }, [order, upsell]);

    const toggleExpand = () => {
        if (isOpen) {
            tl.to(expandableRef.current, {height: 0, duration: 0.25});
        } else {
            tl.set(expandableRef.current, {height: 'auto'});
            tl.from(expandableRef.current, {height: 0, duration: 0.25});
        }
        setIsOpen(!isOpen);
    };

    const smbVoip = () => {
        return (
            <div>$0/mth <span className='regular font-smaller'>
                <p className="m-0 font-tiny">
                    <span>for the first 6 months<br /></span>
                    <span>then $10/mth ongoing<br /></span>
                </p>
                </span>
            </div>
        );
    }

    const mobilePromo = (planId, currentData) => {
        const promoPlanList = [417, 438, 436, 420, 438, 436];
        if (!promoPlanList.includes(planId)) {
            return currentData;
        }
    }

    const getPriceIncreaseBanner = (notes) => {
        return (
            <div className='mt-3'>
                {notes.map((item, index) => {
                    return (
                        <Banner content={item} key={index}/>
                    )
                })}
            </div>
        )
    }
    const getMonthlyTotalBroadbandPrice = () => {
        
        return orderOverride.bundle ? parseFloat(orderOverride.monthlyTotal) - parseFloat(orderOverride.bundleTotal) : orderOverride.monthlyTotal;

    }

    const MonthlyTotalBroadband = () => {
        let monthlyTotal = getMonthlyTotalBroadbandPrice();
        if (!orderOverride.priceArray || orderOverride.priceArray.length === 1) {
            return <p className={styles.price}>Your monthly total: {formatDecimals(orderOverride.monthlyTotal, true)}/mth</p>;
        }

        return (
            <div>
                <p className={styles.price}>Your monthly total: {orderOverride.bundle ? <del>{formatDecimals(orderOverride.monthlyTotal,true)}</del> : ''} {formatDecimals(monthlyTotal, true)}/mth</p>
                <p className="m-0 mt-1 font-tiny text-center">
                    {orderOverride.bundle && orderOverride.internetTotal ? 
                      orderOverride.internetTotal.length > 0 &&  orderOverride.internetTotal.map((priceString, key) => {
                            return (
                                <span key={key}>{priceString}<br /></span>
                            );
                        })
                    :orderOverride.priceArray.map((priceString, key) => {
                        return (
                            <span key={key}>{priceString}<br /></span>
                        );
                    })}
                </p>
            </div>
        );
    };

    const BroadbandSummary = () => {
        return (
            <React.Fragment>
                <div className={styles.separator} />
                {orderOverride.isFlip ?
                    <React.Fragment>
                        <Row className="mb-2 gx-3">
                            <Col className="text-nowrap">
                                <span className="d-none d-lg-inline">Your </span>Flip to Fibre™ plan:
                            </Col>
                            <Col className="bold" xs={7} lg={6} dangerouslySetInnerHTML={{__html: orderOverride.planName}} />
                        </Row>
                    </React.Fragment>
                    :
                    <Row className="mb-2 gx-3">
                        <Col>Plan name: </Col>
                        <Col className="bold" xs={7} lg={6} dangerouslySetInnerHTML={{__html: orderOverride.planName}} />
                    </Row>
                }
                <Row className="mb-2 gx-3">
                    <Col>Speed tier: </Col>
                    <Col className="bold" xs={7} lg={6} dangerouslySetInnerHTML={{__html: orderOverride.speedTier}} />
                </Row>
                {orderOverride.bundle ?
                    <>
                        <Row className="mb-2 gx-3">
                            <Col>Internet total: </Col>
                            <Col xs={7} lg={6}>
                                <p className="bold m-0"><del>${formatDecimals(orderOverride.broadbandPlanCost)}</del> ${formatDecimals(orderOverride.internetCost)}/mth</p>
                                {orderOverride.internetTotal && orderOverride.internetTotal.length > 0 ?
                                    <p className="m-0 font-tiny">
                                        {orderOverride.internetTotal.map((descString, key) => {
                                            return (
                                                <span key={key}>{descString}<br /></span>
                                            );
                                        })}
                                    </p>
                                    : ''}
                            </Col>
                        </Row>
                        <Row className="mb-2 gx-3">
                            <Col>Data: </Col>
                            <Col xs={7} lg={6}>
                                <p className="bold m-0">{orderOverride.monthlyData}</p>
                            </Col>
                        </Row>
                    </>
                :
                    <Row className="mb-2 gx-3">
                        <Col>Monthly data: </Col>
                        <Col xs={7} lg={6}>
                        <p className="bold m-0">{orderOverride.monthlyData} | {formatDecimals(orderOverride.broadbandPlanCost, true)}/mth</p>
                        {orderOverride.broadbandPlan && orderOverride.broadbandPlan.length > 0 ?
                            <p className="m-0 font-tiny">
                                {orderOverride.broadbandPlan.map((descString, key) => {
                                    return (
                                        <span key={key}>{descString}<br /></span>
                                    );
                                })}
                            </p>
                            : ''}
                        </Col>
                    </Row>
                }
                {orderOverride.bundle && orderOverride.bundleTotal > 0 ?
                    <Row className="mb-2 gx-3">
                        <Col>Bundle discount: </Col>
                        <Col xs={7} lg={6}>
                            <p className="bold m-0">${orderOverride.bundleTotal}/mth <span className="regular font-smaller">applied above</span></p>
                        </Col>
                    </Row>
                : ''}
                {orderOverride.upfrontModem ?
                    <Row className="mb-2 gx-3">
                        <Col>Modem + shipping: </Col>
                        <Col className="bold" xs={7} lg={6}>{orderOverride.zteCost > 0 ? `$${orderOverride.zteCost}` : ' Free'} <span className="regular font-smaller">(valued at ${orderOverride.modemDefaultCost ?? '200'})</span>
                        <span className="regular font-smaller">{orderOverride.zteShipping > 0 ? ` + $${orderOverride.zteShipping} shipping` : ' (Free shipping included)'}</span>
                        </Col>
                    </Row>
                    : ''}
                {orderOverride.extenderModem > 0 ?
                    <Row className="mb-2 gx-3">
                        <Col>{orderOverride.extenderQuantity > 1 ? `${orderOverride.extenderQuantity} x Modem extenders + shipping` : 'Extender + shipping'}:</Col>
                        <Col className="bold" xs={7} lg={6}>{orderOverride.extenderCost > 0 ? `$${orderOverride.extenderCost}` : 'Free'} <span className="regular font-smaller">{orderOverride.extenderShipping > 0 ? ` (includes $${orderOverride.extenderShipping} shipping)` : ' (Free shipping included)'}</span></Col>
                    </Row>
                    : ''}
                {orderOverride.router && mapRouterData[orderOverride.router] ?
                    <Row className="mb-2 gx-3">
                        <Col>Router: </Col>
                        <Col className="bold" xs={7} lg={6}>${mapRouterData[orderOverride.router].price} <span className="regular font-smaller">({mapRouterData[orderOverride.router].title})</span></Col>
                    </Row>
                    : ''}
                {orderOverride.backup ?
                    <Row className="mb-2 gx-3">
                        <Col>4G wireless failover service: </Col>
                        <Col className="bold" xs={7} lg={6}>$100/mth</Col>
                    </Row>
                    : ''}
                {orderOverride.unlimitedPhone ?
                    <Row className="mb-2 gx-3">
                        <Col>{orderOverride.isSmb ? 'Unlimited business calls' : 'VoIP Unlimited call pack'}: </Col>
                        <Col className="bold" xs={7} lg={6}>{orderOverride.isSmb ? smbVoip(): '$10/mth'}</Col>
                    </Row>
                    : ''}
                {orderOverride.paygVoip ?
                    <Row className="mb-2 gx-3">
                        <Col>VoIP Pay-as-you-go: </Col>
                        <Col className="bold" xs={7} lg={6}>$0/mth</Col>
                    </Row>
                    : ''}
                {orderOverride.promoCredit !== 0 ?
                    <Row className="mb-2 gx-3">
                        <Col>Promotional Credit: </Col>
                        <Col className="bold" xs={7} lg={6}>${orderOverride.promoCredit}</Col>
                    </Row>
                    : ''}
                {orderOverride.isBusiness ?
                    <Row className="mb-2 gx-3">
                        <Col>Activation fee: </Col>
                        <Col className="bold d-flex align-items-center" xs={7} lg={6} style={{ lineHeight: '20px' }}>${orderOverride.activationPrice}</Col>
                    </Row>
                    : ''}
                <Row className="mb-2 gx-3">
                    <Col>Contract length: </Col>
                    <Col className="bold" xs={7} lg={6}>{orderOverride.contract}</Col>
                </Row>
                {orderOverride.newDevelopment ?
                    <Row className="mb-2 gx-3">
                        <Col>New development charge: </Col>
                        <Col className="bold" xs={7} lg={6}>$300</Col>
                    </Row>
                    : ''}
                <Row className="mb-2 gx-3">
                    <Col>Total upfront costs: </Col>
                    <Col className="bold" xs={7} lg={6}>${orderOverride.upfrontCost}</Col>
                </Row>
                <Row className="gx-3">
                    <Col>Total min. charge: </Col>
                    <Col className="bold" xs={7} lg={6}>${orderOverride.minCost}</Col>
                </Row>
                {order.orderNtd ? <Row className="mb-2 gx-3">
                <Col>{NTD_LABEL}</Col>
                <Col className="bold">Included</Col>
                </Row>: ''}
                {orderOverride.mobileBundle ? <MobileAddOn /> : ''}
                <div className={styles.separator} />
                <div className={styles.total}>
                    <MonthlyTotalBroadband />
                </div>
            </React.Fragment>
        );
    };

    const MobileSummary = () => {
        return (
            <React.Fragment>
                <div className={styles.separator} />
                <Row className="mb-2">
                    <Col>Plan name: </Col>
                    <Col className="bold" dangerouslySetInnerHTML={{__html: orderOverride.planName}} />
                </Row>
                <Row className="mb-2">
                    <Col>Monthly data: </Col>
                    <Col className="bold">
                        { orderOverride.includedData }
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>Total min. charge: </Col>
                    <Col className="bold">${orderOverride.minCost}</Col>
                </Row>
                {orderOverride.promoCredit !== 0 ?
                    <Row className="mb-2 gx-3">
                        <Col>Promotional Credit: </Col>
                        <Col className="bold" xs={7} lg={6}>${orderOverride.promoCredit}</Col>
                    </Row>
                    : ''}
                {orderOverride.simType !== "" ?
                    <Row className="mb-2">
                        <Col>Sim Type: </Col>
                        <Col className="bold">{orderOverride.simType}</Col>
                    </Row>
                : ''}

                {orderOverride.features.length > 0 ?
                    <Row className="mb-2">
                        <Col>
                            <p className="m-0 mb-1">Inclusions:</p>
                            <ul>
                                {orderOverride.features.map((item, key) => {
                                    return (
                                        <li key={key} className="font-smaller mb-1" dangerouslySetInnerHTML={{__html: stripTags(item)}} />
                                    );
                                })}
                            </ul>
                        </Col>
                    </Row>
                    : ''}
                <div className={styles.total}>
                    <p className={styles.price}>Your monthly total: {formatDecimals(orderOverride.planPrice, true)}/mth</p>
                </div>
            </React.Fragment>
        );
    };

    const MobileAddOn = () => {
        return (
            <React.Fragment>
                <div className={styles.separator} />
                <Row className="mb-2">
                    <Col>Plan name: </Col>
                    <Col
                        className="bold"
                        dangerouslySetInnerHTML={{ __html: orderOverride.mobileBundleData.planName }}
                    />
                </Row>
                <Row className="mb-2">
                    <Col>Monthly data: </Col>
                    <Col className="bold">
                        { mobilePromo(orderOverride.mobileBundleData.planId, orderOverride.mobileBundleData.includedData) }
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>Monthly cost: </Col>
                    <Col className="bold">{orderOverride.mobileBundleData.price}/mth</Col>
                </Row>
                {orderOverride.simType !== "" ?
                    <Row className="mb-2">
                        <Col>Sim Type: </Col>
                        <Col className="bold">{orderOverride.simType}</Col>
                    </Row>
                    : ''}

                {orderOverride.mobilePriceNotes && orderOverride.mobilePriceNotes.length > 0 ? getPriceIncreaseBanner(orderOverride.mobilePriceNotes): ''}

                {orderOverride.mobileBundleData.features.length > 0 ? (
                    <Row className="mb-2">
                        <Col>
                            <p className="m-0 mb-1">Inclusions:</p>
                            <ul>
                                {orderOverride.mobileBundleData.features.map((item, key) => {
                                    return (
                                        <li
                                            key={key}
                                            className="font-smaller mb-1"
                                            dangerouslySetInnerHTML={{
                                                __html: stripTags(item),
                                            }}
                                        />
                                    );
                                })}
                            </ul>
                        </Col>
                    </Row>
                ) : ''}
            </React.Fragment>
        );
    };

    const SummaryItems = () => {
        if (orderType === TYPE_BROADBAND) {
            return <BroadbandSummary />;
        } else if (orderType === TYPE_MOBILE) {
            return <MobileSummary />;
        } else {
            return '';
        }
    };

    return (
        <div className={styles.summary}>
            <div className="d-lg-none w-100">
                <div className={styles.summaryTitle} onClick={toggleExpand}>
                    <div className="me-4">Your order summary</div>
                    <img src={arrowGreen} alt="Expand" />
                </div>
                <div ref={expandableRef} className={styles.expandable} style={{height: 0}}>
                    <SummaryItems />
                </div>
            </div>
            <div className="d-none d-lg-block w-100">
                <div className="bold font-large text-center">
                    Your order summary
                </div>
                <SummaryItems />
            </div>
        </div>
    );

};

Summary.propTypes = {

};

export default Summary;