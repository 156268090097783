import React from "react";
import PropTypes from "prop-types";
import { CheckGreenCircle, CrossRedCircle } from "../../atoms/Icons/Icons";

const Alert = (props) => {
    const icons = {
        success: <CheckGreenCircle className="text-green-500" />,
        error: <CrossRedCircle />
    }
    const background = {
        success: 'bg-green-50',
        error: 'bg-red-50'
    }
    return(
        <div className={`flex p-2 mt-1 ${background[props.type]} rounded-md`} style={{borderRadius: '.375rem'}}>
            {icons[props.type]}
            <span className={`${props.type === 'success' ? 'bold': ''}`} style={{paddingLeft: '.5rem'}}>{props.content}</span>
        </div>
    )
}

Alert.propTypes = {
    type: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired
}

export default Alert
