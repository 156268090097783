import React from "react";
import PropTypes from "prop-types";
import styles from "./InputLabel.module.scss";

const InputLabel = (props) => {
    return (
        <label className={`${props.isNotBold ? styles.labelNobold :styles.label} ${props.hasError ? styles.error : ''}`}>
            <div>{props.label}</div>
            <div className={styles.subLabel}>{props.subLabel}</div>
        </label>
    );

};

InputLabel.propTypes = {
    label: PropTypes.string,
    subLabel: PropTypes.string,
    hasError: PropTypes.bool
};

export default InputLabel;