import country from './data/country.json';

export const TYPE_BROADBAND = 'broadband';
export const TYPE_MOBILE = 'mobile';
export const TYPE_NBN_EE = 'nbn_ee';

export const TECH_NBN = 'nbn';
export const TECH_FIBRE = 'fibre';

export const HS_TRIAL = 'trial';
export const HS_SUBSCRIBE = 'subscribe';

export const ID_MEDICARE = 'medicare';
export const ID_PASSPORT = 'passport';
export const ID_LICENCE = 'driver_licence';

export const FTTP_HFC_SERVICE_CLASSES = [1, 2, 3, 21, 22, 23, 24];

export const METHOD_CREDIT = 'creditCard';
export const METHOD_DEBIT = 'directDebit';
export const METHOD_NONCE = 'braintreeNonce';
export const METHOD_PCIPAL = 'pcipal';

export const SIM_TYPE_3IN1 = '3IN1';
export const SIM_TYPE_ESIM = 'ESIM';

export const CISCO_ROUTER_C9221 = 'cisco_c9221';
export const CISCO_ROUTER_C11114P = 'cisco_c1111_4p';
export const CISCO_ROUTER_C11118P = 'cisco_c1111_8p';

export const mapRouterData = {
    [CISCO_ROUTER_C9221]: {title: 'Cisco C921', price: 550},
    [CISCO_ROUTER_C11114P]: {title: 'Cisco C1111 4P', price: 900},
    [CISCO_ROUTER_C11118P]: {title: 'Cisco C1111 8P', price: 1025},
};

export const STATES = ['NSW', 'VIC', 'QLD', 'ACT', 'NT', 'WA', 'SA', 'TAS']

export const COUNTRIES = country;
export const COUNTRY_CODE_AUSTRALIA = 'AU'
export const AUTHENTICATE_SUCCESSFUL = 'authenticate_successful'
export const CHALLENGE_REQUIRED = 'challenge_required'

export const SOURCE_WHISTLEOUT = 'whistleout';
export const CODE_WHISTLEOUT = 'WOEX2223';

export const SECRET_PASS = 'Y3T2wcAX2SpBaAZDJYaMHCGn2Yd3RHtz'

export const AGENT_CODE_EXE = 'EXEAGENT1750';

export const CREDIT_CARD = 'credit card';
export const DIRECT_DEBIT = 'direct debit';

export const CREDIT_CARD_FEE_TEXT = 'Payments made by Credit card incur a 1% transaction fee. You can avoid this fee by electing to pay via Direct Debit.';
export const DIRECT_DEBIT_OPTION = 'This option is available in your MyExetel portal after order confirmation, under Payment and Invoices.'
export const ENV_PRODUCTION='production';
export const EXETEL_URL = 'https://www.exetel.com.au';
export const NBN_TECH_HFC = 'HFC'
export const NBN_TECH_FTTC = 'FTTC'
export const NTD_LABEL = "Network Termination Device (NTD):"
export const NCD_LABEL = "Network Connection Device (NCD):"
export const PROVIDER_NBN = 'nbn'
export const SERVICE_CLASSES = [20, 21, 22, 23, 24, 30, 31, 32, 33, 34]
export const AUTHORISED_TRANSFER_OPTIONS = [{label: 'Yes', value: true}, {label: 'No', value: false}]


export const CATEGORY_BASICBIZ = 'basicbiz'
export const CATEGORY_TOTALBIZ = 'totalbiz'
export const CATEGORY_SUPERBIZ = 'superbiz'
export const CATEGORIES = [CATEGORY_BASICBIZ, CATEGORY_TOTALBIZ, CATEGORY_SUPERBIZ]

export const CONVERSION_NBN = 'nbn'
export const CONVERSION_FIBRE = 'fibre'
export const CONVERSION_F2F = 'f2f'
export const CONVERSION_MOBILE = 'mobile'
export const CONVERSION_SMB_NBN = 'smbNbn'
export const CONVERSION_SMB_F2F = 'smbF2f'

export const MFA_SMS = 'MfaOverSMS'
export const MFA_EMAIL = 'MfaOverEmail'

export const UNCONNECTED_OFFER_TYPE = 'Unconnected'