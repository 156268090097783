import React, { useEffect, useContext, useState } from "react";
import styles from "./ConnectionMobile.module.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OrderContext from "../../OrderContext";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import BackNextButtons from "../../components/BackNextButtons/BackNextButtons";
import { METHOD_CREDIT } from "../../constants";
import { handleError, pushEvent , convertStringToBool, checkNumber} from "../../globals";
import MobileForm from "../../components/MobileForm/MobileForm";
import DataStore from "../../DataStore";
import {connectionSubmitEvent, addShippingInfoConnectionPageEvent} from "../../events"

const ConnectionMobile = () => {
    const { order, orderType, updateOrder, availableNumbers } = useContext(OrderContext);
    const {
        register,
        watch,
        handleSubmit,
        control,
        reset,
        formState: { errors },
        setValue,
    } = useForm();

    let watchKeepPhone = watch("portNumber");

    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [mobileIsVerified, setMobileIsVerified] = useState(order.mobileIsVerified);
    const [validationErrors, setValidationErrors] = useState(null);
    const [isServiceAddressSelected, setIsServiceAddressSelected] = useState(false)
    const [isDeliveryAddressSelected, setIsDeliveryAddressSelected] = useState(false)
    const [resetMobileVerification, setResetMobileVerification] = useState(false)
    const [accountNumberValidation, setAccountNumberValidation] = useState(false)

    useEffect(() => {
        pushEvent("connectionPageView");
    }, []);

    useEffect(() => {
        // Form needs to be reset after an API call in order to update the values
        reset(order);
    }, [order]);

    const handleInputKeyDown = (value) => {
        if (!checkNumber(value)) {
            setAccountNumberValidation('Please use digits only (0–9) for the account number.')
        } else {
            setAccountNumberValidation(false)
        }
    }

    const handleSubmitEvent = (data) => {
        data.portNumber = convertStringToBool(data.portNumber) //typecast the string to boolean
        watchKeepPhone = convertStringToBool(watchKeepPhone) //typecast the string to boolean
        
        if (!isServiceAddressSelected) {
            setValidationErrors("Please select a valid home address from the options provided.") 
            return
        }

        if (data.deliveryAddress) {
            if (!isDeliveryAddressSelected) {
                setValidationErrors("Please select a valid delivery address from the options provided.")
                return
            }
        }

        if(data.portNumber) {
            const verifiedMobileNumber = DataStore.getItem('verifiedMobileNumber') ? DataStore.getItem('verifiedMobileNumber'): ''
            if (verifiedMobileNumber.length > 0) {
                if(data.currentMobileNumber !== verifiedMobileNumber) {
                    setResetMobileVerification(true)
                    setValidationErrors("Your existing mobile number needs to be verified by sending a code above.");
                    setIsLoading(false);
                    return
                }
            }

            if (accountNumberValidation) {
                setValidationErrors(accountNumberValidation)
                return
            }
        }

        setIsLoading(true);
        setValidationErrors(null);
        data.validateService = true;
        data.page = "Connection mobile";
        if (mobileIsVerified || !watchKeepPhone) {
            data.completed = false;
            updateOrder({
                ...data,
                mobileIsVerified: mobileIsVerified,
                paymentMethod: METHOD_CREDIT,
            })
                .then(() => {
                    connectionSubmitEvent(order)
                    addShippingInfoConnectionPageEvent(orderType, order)
                    history.push("/payment");
                })
                .catch((error) => {
                    if (error.response.status === 422 || error.response.status === 403) {
                        // Show validation error
                        if (error.response.data && error.response.data) {
                            setValidationErrors(error.response.data.errors);
                        } else {
                            setValidationErrors("Oops! It appears something went wrong, please try again.");
                        }
                    } else {
                        //Some other error occurred, log and show error screen
                        setValidationErrors("Oops! It appears something went wrong, please try again.");
                        handleError("Error submitting mobile connection details in handleSubmitEvent.", error.response);
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setValidationErrors("Your existing mobile number needs to be verified by sending a code above.");
            setIsLoading(false);
        }
    };


    return (
        <div className={styles.container}>
            <Row className={styles.content}>
                <Col>
                    <form onSubmit={handleSubmit(handleSubmitEvent)}>
                        <MobileForm
                            register={register}
                            errors={errors}
                            control={control}
                            setValue={setValue}
                            watch={watch}
                            setMobileIsVerified={setMobileIsVerified}
                            validationErrors={validationErrors}
                            availableNumbers={availableNumbers}
                            setIsServiceAddressSelected={setIsServiceAddressSelected}
                            setIsDeliveryAddressSelected={setIsDeliveryAddressSelected}
                            resetMobileVerification={resetMobileVerification}
                            handleInputKeyDownAccount={handleInputKeyDown}
                            accountNumberValidation={accountNumberValidation}
                        />
                        <BackNextButtons handleSubmit={handleSubmit(handleSubmitEvent)} handleBack={() => history.push("/")} />
                    </form>
                </Col>
            </Row>
        </div>
    );
};

ConnectionMobile.propTypes = {};

export default ConnectionMobile;