import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import styles from "./InputField.module.scss";
import iconError from "../../images/icon-error.svg";
import MaskedInput from "react-text-mask";
import { Controller } from "react-hook-form";
import InputLabel from "../InputLabel/InputLabel";

const InputField = forwardRef((props, ref) => {
    const dobMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
    const medicareExpiryGreenMask = [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
    const voipMask = [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
    const mobileMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    const cardExpiryMask = [/\d/, /\d/, '/', /\d/, /\d/];
    const cardNoMask = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
    const bsbMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    const abnMask = [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/];

    const getMask = () => {
        if (props.type === 'voip') {
            return voipMask;
        } else if (props.type === 'dob') {
            return dobMask;
        } else if (props.type === 'medicareExpiry') {
            return dobMask;
        } else if (props.type === 'medicareExpiryGreen') {
            return medicareExpiryGreenMask;
        } else if (props.type === 'creditCardExpiry') {
            return cardExpiryMask;
        } else if (props.type === 'creditCardNumber') {
            return cardNoMask;
        } else if (props.type === 'bankBsb') {
            return bsbMask;
        } else if (props.type === 'mobile') {
            return mobileMask;
        } else if (props.type === 'abn') {
            return abnMask;
        }

        return false;
    };

    const validateDate = (value) => {
        if (!value || props.name !== 'expectedInstallationDate') return true
        const [day, month, year] = value.split('/')
        const inputDate = new Date(`${year}-${month}-${day}`)
        const today = new Date()
        const maxDate = new Date()
        maxDate.setDate(today.getDate() + 30)

        if (inputDate > maxDate) {
            return 'Date must be within 30 days from today'
        }
        return true
    }

    return (
        <div className={`${props.hasSuccess ? styles.success: ''} ${props.hasError ? styles.error : ''} ${props.noMargin ? '' : 'mb-3'}`}>
            <div className={styles.inner} style={props.hasError ? {paddingRight: '30px'} : {}}>
                <div className={`${props.labelClass ? props.labelClass: ''} d-flex gap-1`} style={{alignItems:'center'}}>
                    {props.label || props.subLabel ? <InputLabel label={props.label} subLabel={props.subLabel} hasError={props.hasError} isNotBold={props.isNotBold}/> : ''}
                    {props.labelIcon ? props.labelIcon: ''}
                </div>
                <div className={styles.inputContainer}>
                    {getMask() ?
                        <Controller
                            control={props.control}
                            name={props.name}
                            value={props.defaultValue}
                            rules={{
                                required: props.required,
                                validate: validateDate
                            }}
                            render={({ field }) => (
                                <MaskedInput
                                    {...field}
                                    className={styles.input}
                                    mask={getMask()}
                                    placeholder={props.placeholder}
                                    guide={false}
                                    required={props.required}
                                    ref={(ref) => {
                                        field.ref(ref ? ref.inputElement : null);
                                    }}
                                />
                            )}
                        />
                        :
                       props.register ? <input
                            className={styles.input}
                            placeholder={props.placeholder}
                            type={props.type}
                            autoComplete={props.autoComplete}
                            defaultValue={props.defaultValue}
                            maxLength={props.maxLength}
                            onKeyDown={props.handleKeyDown}
                            onBlur={props.handleBlur}
                            readOnly={props.readOnly}
                            {...props.register(props.name, { required: props.required, onBlur: props.handleBlur, pattern: props.pattern })}
                        />: 
                        <>
                            <input
                                key={props.key}
                                className={styles.input}
                                placeholder={props.placeholder}
                                type={props.type}
                                autoComplete={props.autoComplete}
                                defaultValue={props.defaultValue}
                                maxLength={props.maxLength}
                                onKeyDown={props.handleKeyDown}
                                onBlur={props.handleBlur}
                                readOnly={props.readOnly}
                                ref={ref}
                            />
                            {props.icon ? <span style={{
                                position: 'absolute',
                                paddingRight: '12px',
                                right: '8px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer',
                            }}
                            >
                                {props.icon}
                            </span>: ''}
                        </>
                        
                    }
                    {props.hasError ?
                        <div className={styles.errorIcon}>
                            <img src={iconError} alt="Error" />
                        </div>
                        : ''}
                </div>
                {(props.hasError && props.name === 'expectedInstallationDate') ? 
                    <p style={{color: 'red', paddingTop: '5px'}}>Please select a date no more than 30 days from now</p>
                : ''}
            </div>
        </div>
    );
})

InputField.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    subLabel: PropTypes.string,
    register: PropTypes.func,
    required: PropTypes.bool,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    hasError: PropTypes.bool,
    defaultValue: PropTypes.string,
    autoComplete: PropTypes.string,
    control: PropTypes.object,
    maxLength: PropTypes.number,
    noMargin: PropTypes.bool,
    readOnly: PropTypes.bool,
    handleKeyDown: PropTypes.func,
    handleBlur: PropTypes.func,
    isNotBold: PropTypes.bool
};

export default InputField;