import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styles from "./UpsellPlanCard.module.scss";
import iconTick from "../../images/icon-tick.svg";
import Button from "../Button/Button";

const UpsellPlanCard = (props) => {
    const upsell = useSelector((state) => state.upsell.value);
    const price = parseInt(props.plan.price.replace("$", ""), 10);
    const isPlanSelected = upsell.mobileBundleData && props.plan.id === upsell.mobileBundleData.planId;

    const Upsell = () => (
        <div key={props.plan.id} >
            <div className={styles.innerIndigo}>
                <div>
                    <p className={`regular font-xlarge m-0 ${styles.planTitle}`}>{props.plan.title} plan</p>
                </div>
                <div className={`d-flex mt-3`}>
                    <p className="bold font-xlarge color-malachite mb-0">${price}</p>
                </div>
                {props.plan.priceNotes && props.plan.priceNotes.length > 0 ? props.plan.priceNotes.map((item) => {
                        return item.trim() ?  <p className="m-0">({item})</p>: ''
                    }): ''}
                     
            </div>
            <div className={styles.inner}>
                <div className="font-large bold">{props.plan.data} data</div>
            </div>
            <div className={styles.innerGrey}>
                {props.plan.features.map((feature, index) => {
                    return (
                        <div className={styles.benefits} key={index}>
                            <img src={iconTick} alt="Icon Tick" className={styles.icon_tick} key={index} />
                            <p>{feature}</p>
                        </div>
                    );
                })}
                {props.plan.dataAdditional.map((feature, index) => {
                    return (
                        <div className={styles.benefits} key={index}>
                            <img src={iconTick} alt="Icon Tick" className={styles.icon_tick} key={index} />
                            <p>{feature}</p>
                        </div>
                    );
                })}
                <p className="bold d-flex justify-content-start mt-3">
                    Total min ${price} | ${props.plan.costPerGb}/GB
                </p>
            </div>
            <p className={`bold ${styles.mb_0} pb-2 p-3`}>Save $5/mth off your broadband bill</p>
            <div className="pt-2 pb-3">
                {isPlanSelected ? (
                    <div className="d-flex align-items-baseline justify-content-between px-4">
                        <p className={`${styles.labelSelected} bold`}>Selected</p>
                        <Button handleClick={props.handleClickRemove} className={styles.removeBtn}>
                            Remove
                        </Button>
                    </div>
                ) : (
                    <div className="px-5">
                    <Button handleClick={() => props.handleAddToCart(props.plan)} className={styles.addButton}>
                        Add to cart
                            </Button>
                            </div>
                )}
            </div>
        </div>
    );

    return (
        <div className={styles.card} key={upsell.mobileBundleData}>
            {isPlanSelected ? (
                <div className={styles.activeBorder}>
                    <Upsell />
                </div>
            ) : (
                <div className={styles.cardShadow}>
                    <Upsell />
                </div>
            )}
        </div>
    );
};

UpsellPlanCard.propTypes = {
    plan: PropTypes.object.isRequired,
    handleAddToCart: PropTypes.func.isRequired,
    handleClickRemove: PropTypes.func.isRequired,
};

export default UpsellPlanCard;
