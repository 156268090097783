import React, {useEffect, useState} from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import "./App.scss";
import { OrderProvider } from "../../OrderContext";
import Header from "../../components/Header/Header";
import Container from "react-bootstrap/Container";
import Account from "../../pages/Account/Account";
import Page from "../Page/Page";
import Summary from "../../components/Summary/Summary";
import Payment from "../../pages/Payment/Payment";
import Success from "../../pages/Success/Success";
import SuccessPanel from "../../components/SuccessPanel/SuccessPanel";
import useQuery from "../../hooks/useQuery";
import ConnectionBroadband from "../../pages/ConnectionBroadband/ConnectionBroadband";
import ConnectionMobile from "../../pages/ConnectionMobile/ConnectionMobile";
import UpsellMobileBundle from "../../components/UpsellMobileBundle/UpsellMobileBundle";
import {
    HS_TRIAL,
    TYPE_BROADBAND,
    TYPE_MOBILE,
} from "../../constants";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import DataStore from "../../DataStore";
import DataSource from "../../DataSource";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import LoadingOverlay from "../LoadingOverlay/LoadingOverlay";
import NotFound from "../../pages/NotFound/NotFound";
import { handleError, decryptData } from "../../globals";
import { agentLogout, isTokenValid } from "../../utils/AgentUtil";
import { jwtDecode } from "jwt-decode"
import { Mfa } from "../../pages/MFA/Mfa";

const allowedTypes = [TYPE_MOBILE, TYPE_BROADBAND];
const requiredValues = {
    [TYPE_BROADBAND]: ['type', 'technology', 'sq_address', 'service_class', 'plan_id', 'contract_term', 'unlimited_phone', 'payg_phone', 'modem', 'extender', 'hs', 'new_dev'],
    [TYPE_MOBILE]: ['type', 'plan_id', 'contract_term'],
};
const additionalValues = {
    [TYPE_BROADBAND]: ['flip', 'interim_plan', 'smb', 'fdclid', 'backup', 'business', 'router', 'contended_type', 'utm_source', 'referral_code', 'internal', 'service_location_id', 'conversation_id', 'active_service'],
    [TYPE_MOBILE]: ['fdclid', 'conversation_id', 'utm_source'],
};

const mapUrlDataToState = {
    'type': 'type',
    'sq_address': 'serviceAddress',
    'service_class': 'serviceClass',
    'plan_id': 'planId',
    'contract_term': 'contractTerm',
    'unlimited_phone': 'unlimitedPhone',
    'payg_phone': 'paygVoip',
    'modem': 'upfrontModem',
    'extender': 'extenderModem',
    'hs': 'homeSecurePlan',
    'new_dev': 'newDevelopment',
    'fdclid': 'fdclid',
    'technology': 'technology',
    'flip': 'isFlip',
    'interim_plan': 'interimPlan', //for flipToFibre
    'smb': 'isSmb',
    'business': 'isBusiness',
    'backup': 'backup',
    'router': 'router',
    "contended_type": 'isContended',
    'utm_source': 'utm_source',
    'referral_code': 'referrerCode',
    'internal': 'internal',
    'service_location_id': 'serviceLocationId',
    'conversation_id': 'conversationId',
    'active_service': 'activeService'
};

/*
    See README.md for test URLs
*/
const App = () => {
    const location = useLocation();
    const query = useQuery();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [step, setStep] = useState(1);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [orderType, setOrderType] = useState(null);
    const [homeSecurePlan, setHomeSecurePlan] = useState(HS_TRIAL);
    const [isUpsellMobileBundleSelected, setIsUpsellMobileBundleSelected] = useState(false);
    const [upsellModule, setUpsellModule] = useState(null);
    const [isLoadingUpsellModule, setIsLoadingUpsellModule] = useState(true);

    const [order, setOrder] = useStateWithCallbackLazy({
        code: null,
        agreedToAllConditions: false,
        dslServiceConditionsAgreed: false,
        completed: false,
        isFlip: false,
        fdclid: null,
        source: null,

        // Account details
        salutation: "",
        firstName: "",
        lastName: "",
        dob: "",
        contact: "",
        email: "",
        contactMethod: "mobile",
        identifierType: "",
        identifier: "",
        // driver license
        idState: "",
        cardNumber: "",
        // passport
        country: "",
        // medicare
        individualRefNumber: null,
        cardType: "",
        idExpiry: "",
        // personal details
        idFirstName: "",
        idMiddleName: "",
        idLastName: "",
        fullName: "",
        // checkBox
        idCheck: false,
        // login check
        validIdCheck: false,

        simType: "",
        internal: false,

        // Connection details (broadband)
        provider: "",
        lotNumber: "", // Required for Opticomm
        deliveryAddress: "",
        deliveryName: "",
        company: "",
        newPhoneRequired: null,
        serviceNumber: "", // Active phone line number
        transferPstn: null,
        currentPhoneProviderAccountNumber: "",
        checkActivation: true,
        expectedInstallationDate: null,

        // Non FTTP/HFC voip fields
        hasActivePhoneLine: null,
        phoneBundleConditionsAgreed: null,

        // SMB only fields
        isSmb: false,
        companyNumber: "", // SMB
        companyName: "", // SMB

        // Business only fields
        isBusiness: false,
        backup: false,
        router: null,
        isContended: false,

        // Connection details (mobile)
        contractTerm: 0,
        portNumber: null, // true or false
        newMobileNumber: "", // New Number - if portNumber = false
        // Porting number - if portNumber = true
        currentMobileNumber: "",
        mobileIsVerified: false,
        currentProvider: "",
        currentProviderPlanType: null, // Customer selects this. prePaid or postPaid
        currentProviderNumber: null, // Customers current account number with their current provider. Only required for postPaid
        currentAccountDob: null, // Current service holders DOB. This might be different than the customers DOB - Eg, child / parents phone. Only required for prePaid
        conditionsAgreed: "true",

        // Payment details
        paymentMethod: "",
        bankName: "",
        bankBranch: "",
        bankBsb: "",
        bankAccountName: "",
        bankAccountNumber: "",
        directDebitConditionsAgreed: "",
        creditCardNumber: "",
        creditCardHolderName: "",
        creditCardExpiry: "",
        creditCardCvc: "",
        referrerCode: "",
        agentCode: "",
        conversationId: "",

        // Set via URL
        planId: null,
        serviceAddress: "",
        serviceClass: null,
        unlimitedPhone: false,
        paygVoip: false,
        upfrontModem: false,
        extenderModem: 0,
        homeSecurePlan: HS_TRIAL,
        newDevelopment: false,
        serviceLocationId: "",

        // Set via API response
        broadbandPlanCost: 0,
        speedTier: "",
        monthlyData: "",
        monthlyTotal: 0, //mobile & broadband
        upfrontCost: 0,
        totalMinCost: 0,
        contract: "",
        promoCredit: 0,
        activationPrice: 0,

        // Set via API for Mobile
        planName: null,
        includedData: null,
        planPrice: 0,
        excessPerGb: 0,
        features: [],
        priceNotes: [],
        mobilePriceNotes: [],
        minCost: 0,
        newCustomerPromotion: "",

        // Optional
        freeMonths: 0, // Give customer free months. not required at the moment.
        nonce: '',
        termsOfUse: 1,
        orderNtd: null,
        previousProvider: '',
        isEndUserAuthorised: null,
        activeService: null,
        isTransfer: false,
    });
    const [availableNumbers, setAvailableNumbers] = useState([])

    useEffect(() => {
        initStep();
    }, [location.pathname]);

    useEffect(() => {
        validateGetData();
        loadUpsellModule()
            .then(() => setIsLoadingUpsellModule(false));
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get("agent-token");

        if (token) {
            try {
                const decodedToken = jwtDecode(token)
                if (isTokenValid(decodedToken.exp)) {
                    localStorage.setItem('orderFormToken', token);
                    if (decodedToken.name && decodedToken.agent_code) {
                        const firstName = decodedToken.name.split(' ')[0]
                        localStorage.setItem('agentUsername', firstName);
                        localStorage.setItem('agentCode', decodedToken.agent_code);
                        localStorage.setItem('expiry', decodedToken.exp);
                        localStorage.setItem('isAgent', 'true');
                    }
                } else {
                    agentLogout();
                }
            } catch (error) {
                agentLogout();
            }
        } else if (localStorage.getItem('isAgent') === null){
            agentLogout();                                                                                           
        } else if (localStorage.getItem('isAgent') === 'true') {
            if (!isTokenValid(parseInt(localStorage.getItem('expiry'), 10))) {
                agentLogout();
            }
        }
    }, []);

    const validateGetData = () => {
        let type = query.get("type") ? query.get("type") : DataStore.getItem("orderType");
        let code = query.get("code") ? query.get("code") : DataStore.getItem("code");
        if (!validateUrlType(type)) {
            return;
        }

        //todo: Not currently using missingUrlValues, but may use in the future if we load orders off storage
        let { orderData, missingUrlValues } = filterAndSetRequiredValues(code, type, {}, false);
        orderData = filterAndSetAdditionalValues(type, orderData);
        initNewOrder(code, type, orderData, missingUrlValues);
    };

    const validateUrlType = (type) => {
        if (!type || (type && !allowedTypes.includes(type))) {
            throwError(`Invalid or missing order type.`);
            return false;
        }

        cleanupStorage();
        setOrderType(type);
        DataStore.setItem("orderType", type);

        return true;
    };

    const cleanupStorage = () => {
        // If code/type exists in the URL - make sure it matches storage, otherwise clear storage to be safe
        if (
            (query.get("type") && query.get("type") !== DataStore.getItem("orderType")) || (query.get("code") && query.get("code") !== DataStore.getItem("code"))) {
            DataStore.clearAll();
        }
    };

    const filterAndSetRequiredValues = (code, type, orderData, missingUrlValues) => {
        requiredValues[type].forEach((required) => {
            let value = query.get(required) || query.get(required) === 0 ? query.get(required) : false;
            if (!value && value !== 0) {
                missingUrlValues = true;
                if (!code) {
                    // If there are missing values and no code saved in session, throw error and redirect
                    throwError(`Missing required value ${required} from URL.`);
                }
            } else {
                //todo: Can probably refactor this to only save the token, the rest can come from the API
                //todo: Also could future proof it by using redux rather than accessing localStorage directly
                if (!isNaN(value)) {
                    value = Number(value);
                }
                orderData[mapUrlDataToState[required]] = value;
                if (required === "hs") {
                    setHomeSecurePlan(value);
                }
            }
        });

        return { orderData: orderData, missingUrlValues: missingUrlValues };
    };

    const filterAndSetAdditionalValues = (type, orderData) => {
        additionalValues[type].forEach((additionalValue) => {
            let value = query.get(additionalValue) || query.get(additionalValue) === 0 ? query.get(additionalValue) : false;
            if (!isNaN(value)) {
                value = Number(value);
            }
            orderData[mapUrlDataToState[additionalValue]] = value;
        });

        return orderData;
    };

    const initNewOrder = (code, type, orderData, missingUrlValues) => {
        handleGetNumbers();
        const newOrder = { ...order, ...orderData };        
        setOrder(newOrder, () => {
            // Only use saved code if the URL is missing values ie. it should only have code & type not sq_address etc
            // Otherwise if the URL has all values present, it should override any stored code
            if (code && missingUrlValues) {
                reloadExistingOrder(type, code);
            } else if (type === TYPE_BROADBAND) {
                initBroadbandOrder(newOrder);
            } else if (type === TYPE_MOBILE) {
                initMobileOrder(newOrder);
            }
        });
    };


    const handleGetNumbers = () => {
        DataSource.getAvailablePhoneNumbers()
            .then((response) => {
                if (response.data) {
                    let data = [];
                    response.data.forEach((item) => {
                        data.push({ title: item.mobile_number, value: item.mobile_number });
                    });
                    setAvailableNumbers(data);
                }
            })
            .catch((error) => {
                console.error("Sorry cannot load numbers !! ", error)
            });
    };

    const reloadExistingOrder = (type, code) => {
        DataSource.updateOrder(type, code, {}, order.isBusiness)
            .then((response) => {
                setOrder({
                    ...order,
                    code: code,
                    ...response.data.data.request,
                });
                setIsLoading(false);
                DataStore.setItem("code", code);
            })
            .catch((error) => {
                DataStore.clearAll();
                throwError(error);
            });
    };

    const initBroadbandOrder = (orderData) => {
        let flipVars = {};
        if (orderData.isFlip) {
            flipVars = { isFlip: true, flipSpeed: orderData.interimPlan };
        }
        
        let initOrder = orderData.isBusiness ? data => DataSource.initNbnEEOrder(data) : data => DataSource.initBroadbandOrder(data);        
        initOrder({
            serviceAddress: orderData.internal ? orderData.serviceAddress : decryptData(orderData.serviceAddress),
            planId: orderData.planId,
            serviceClass: orderData.serviceClass,
            contractTerm: orderData.contractTerm,
            newDevelopment: orderData.newDevelopment,
            upfrontModem: orderData.upfrontModem,
            extenderModem: orderData.extenderModem,
            homeSecurePlan: orderData.homeSecurePlan,
            unlimitedPhone: orderData.unlimitedPhone,
            paygVoip: orderData.paygVoip,
            router: orderData.router,
            isContended: orderData.isContended,
            referrerCode: orderData.referralCode ? orderData.referralCode : '',
            serviceLocationId: orderData.serviceLocationId,
            ...flipVars,
        })
            .then((response) => {
                let data = response.data.data;
                DataStore.setItem("code", data.code);
                setOrder({
                    ...orderData,
                    ...data.request,
                    ...{ code: data.code }},
                    () => {
                        setIsLoading(false);
                    }
                );
            })
            .catch((error) => {
                //todo: Throw error but give opportunity to try again
                console.log("ERROR", error);
                setIsLoading(false);
            });
    };

    const initMobileOrder = (orderData) => {
        DataSource.initMobileOrder(orderData.planId, orderData.contractTerm)
            .then((response) => {
                let data = response.data.data;
                DataStore.setItem("code", data.code);
                setOrder({
                        ...orderData,
                        ...data.request,
                        ...{ code: data.code }},
                    () => {
                        setIsLoading(false);
                    }
                );
            })
            .catch((error) => {
                //todo: Throw error but give opportunity to try again
                console.log("ERROR", error);
                setIsLoading(false);
            });
    };

    const throwError = (error) => {
        handleError(error, location);
        setIsLoading(false);
        history.push("/not-found");
    };

    const initStep = () => {
        if (location.pathname === "/connection") {
            setStep(2);
        } else if (location.pathname === "/payment") {
            setStep(3);
        } else {
            setStep(1);
        }
    };

    const updateOrder = async (values) => {
        return new Promise((resolve, reject) => {
            let orderData = { ...order, ...values };

            //Hack: Dont submit orders for JW to API (for testing on prod)
            if (orderData.completed && orderData.email === "josh.warner@superloop.com") {
                resolve({data: { data: { customerId: 1234, serviceId: 4567 } } });
                return;
            }

            DataSource.updateOrder(orderType, orderData.code, orderData, order.isBusiness).then(response => {
                setOrder({...orderData, ...response.data.data.request});
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    };

    const updateOrderState = (values) => {
        let orderData = { ...order, ...values };
        setOrder({ ...orderData });
    };

    const updateOrderNotState = async (values) => {
        return new Promise((resolve, reject) => {
            let orderData = {...order, ...values};
            DataSource.updateOrder(orderType, orderData.code, orderData, order.isBusiness).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    };

    const loadUpsellModule = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/mobile-plans?productType=Mobile&supplier=TELSTRA&crossSell=1`);
        const json = await response.json();
        setUpsellModule(json);
    };

    const buildOrder = () => {
        return {
            order: order,
            orderType: orderType,
            updateOrder: updateOrder,
            updateOrderState: updateOrderState,
            updateOrderNotState: updateOrderNotState,
            availableNumbers: availableNumbers,
            isLoggedIn: isLoggedIn,
            setIsLoggedIn: setIsLoggedIn,
            isUpsellMobileBundleSelected,
            setIsUpsellMobileBundleSelected
        };
    };

    const SidePanel = () => {
        return (
            <React.Fragment>
                <div className="d-none d-lg-block">
                    <Summary />
                </div>
                {/* Only show the HS panel if they have not pre-selected HS from the referring website */}
                {!order.isSmb && !order.isBusiness && orderType === TYPE_BROADBAND ? (
                    <div className="d-block">
                        <UpsellMobileBundle upsellModule={upsellModule} isLoading={isLoadingUpsellModule} />
                    </div>
                ) : ''}
            </React.Fragment>
        );
    };

    return (
        <OrderProvider value={buildOrder()}>
            <Container fluid="xl">
                <LoadingOverlay isLoading={isLoading} isFixed={true}>
                    <ErrorBoundary>
                        <div className={location.pathname !== "/success" ? "mb-5 mb-lg-0" : ""}>
                            <Header step={step} isSuccess={location.pathname === "/success"}/>
                        </div>
                        <Switch>
                            <Route exact path="/">
                                <Page content={<Account isLoggedIn={isLoggedIn} homeSecurePlan={homeSecurePlan} />} side={<SidePanel />} />
                            </Route>
                            <Route path="/connection">
                                <Page
                                    content={orderType === TYPE_MOBILE ? <ConnectionMobile /> : <ConnectionBroadband />}
                                    side={<div className="d-none d-lg-block"><Summary /></div>}
                                />
                            </Route>
                            <Route path="/mfa">
                                <Page content={<Mfa />}/>
                            </Route>
                            <Route path="/payment">
                                <Page content={<Payment orderType={orderType} />} side={<Summary />} />
                            </Route>
                            <Route path="/success">
                                <Page content={<Success />} side={<SuccessPanel />} />
                            </Route>
                            <Route>
                                <Page content={<NotFound />} side={false} />
                            </Route>
                        </Switch>
                    </ErrorBoundary>
                </LoadingOverlay>
            </Container>
        </OrderProvider>
    );
};

export default App;
