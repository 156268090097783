import React, { useState } from "react";
import "./Tooltip.css";
import { CrossIcon } from "../../atoms/Icons/Icons";

const CustomTooltip = (props) => {
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, props.delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className="Tooltip-Wrapper"
      onMouseEnter={showTip}
    >
        
      {props.children}
      {active && (
        <div className={`Tooltip-Tip ${props.direction || "top"}`}>
            <div className="d-flex justify-content-end"><CrossIcon onClick={hideTip} fillColor="white"/></div>
          {props.content}
        </div>
      )}
    </div>
  );
};

export default CustomTooltip;