import React from 'react'

export const InfoIcon = (props) => {
    return (
        <i {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 9.27273C12.5021 9.27273 12.9091 9.67974 12.9091 10.1818V16.5455C12.9091 17.0475 12.5021 17.4545 12 17.4545C11.4979 17.4545 11.0909 17.0475 11.0909 16.5455V10.1818C11.0909 9.67974 11.4979 9.27273 12 9.27273Z" fill={`${props.fillColor ? props.fillColor: '#7422FF'}`}/>
                <path d="M12 8.36364C12.5021 8.36364 12.9091 7.95662 12.9091 7.45455C12.9091 6.95247 12.5021 6.54545 12 6.54545C11.4979 6.54545 11.0909 6.95247 11.0909 7.45455C11.0909 7.95662 11.4979 8.36364 12 8.36364Z" fill={`${props.fillColor ? props.fillColor: '#7422FF'}`}/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 20.1818C16.5187 20.1818 20.1818 16.5187 20.1818 12C20.1818 7.48131 16.5187 3.81818 12 3.81818C7.48131 3.81818 3.81818 7.48131 3.81818 12C3.81818 16.5187 7.48131 20.1818 12 20.1818Z" fill={`${props.fillColor ? props.fillColor: '#7422FF'}`}/>
            </svg>
        </i>
    )
}

export const CheckGreenCircle = (props) => {
    return (
        <i {...props}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="10" r="10" fill="#54B758"/>
                <path d="M13.8047 8.47132C14.0651 8.21097 14.0651 7.78886 13.8047 7.52851C13.5444 7.26816 13.1223 7.26816 12.8619 7.52851L8.66667 11.7238L7.13807 10.1952C6.87772 9.93483 6.45561 9.93483 6.19526 10.1952C5.93491 10.4555 5.93491 10.8776 6.19526 11.138L8.19526 13.138C8.45561 13.3983 8.87772 13.3983 9.13807 13.138L13.8047 8.47132Z" fill="white"/>
            </svg>
        </i>
    )
}

export const CrossRedCircle = (props) => {
    return (
        <i {...props}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="10" fill="#E5000F"/>
            <path d="M7.80457 6.86201C7.54423 6.60166 7.12212 6.60166 6.86177 6.86201C6.60142 7.12236 6.60142 7.54447 6.86177 7.80482L9.05703 10.0001L6.86177 12.1953C6.60142 12.4557 6.60142 12.8778 6.86177 13.1382C7.12212 13.3985 7.54423 13.3985 7.80457 13.1382L9.99984 10.9429L12.1951 13.1382C12.4554 13.3985 12.8776 13.3985 13.1379 13.1382C13.3983 12.8778 13.3983 12.4557 13.1379 12.1953L10.9426 10.0001L13.1379 7.80482C13.3983 7.54447 13.3983 7.12236 13.1379 6.86201C12.8776 6.60166 12.4554 6.60166 12.1951 6.86201L9.99984 9.05727L7.80457 6.86201Z" fill="white"/>
            </svg>
        </i>
    )
}

export const CrossIcon = (props) => {
    return (
        <i {...props}>
            <svg width="12px" height="12px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="NEW-NEW-EXTEL" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="HOMEPAGE-MENU-NAV" transform="translate(-553.000000, -32.000000)" fill={props.fillColor ? props.fillColor: '#004346'}>
                        <g id="Cross" transform="translate(552.000000, 31.581475)">
                            <rect id="Rectangle-Copy-13" transform="translate(13.000000, 12.500000) rotate(-45.000000) translate(-13.000000, -12.500000) " x="11" y="-3" width="4" height="31" rx="2" />
                            <rect id="Rectangle-Copy-15" transform="translate(13.000000, 12.500000) rotate(-135.000000) translate(-13.000000, -12.500000) " x="11" y="-3" width="4" height="31" rx="2" />
                        </g>
                    </g>
                </g>
            </svg>
        </i>
    )
}
